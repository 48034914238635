import React from 'react';



const Hero = () => {

    return (
        <section className="main">
            <div className="container">
                <div className='row'>
                    <h2 className="animatecolor">
                        <div className="line">
                            <span className="hello">Hello</span>
                            <span>I'm a Senior</span>
                        </div>
                    </h2>
                    <h2 className="animatecolor">
                        <div className="line">
                            <span>UI / UX Designer.</span>
                        </div>
                    </h2>
                    <div className="hero-p-row">
                        <p>My name is Figo Naudé. I am an internationally experienced designer who specializes in multidisciplinary UI and UX designs for both mobile and web applications. My passion lies in creating and maintaining beautiful user-centered interfaces that people love to use.</p>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Hero;