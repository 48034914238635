import React from 'react';


const IntroAnimation = () => {
    return (
        <>
            <div className='intro-animation'>
                <div className="intro-overlay">
                </div>
            </div>
        </>
    );
};

export default IntroAnimation;

