import React from 'react';
// Styling and Animation
import styled from 'styled-components';
import { motion } from 'framer-motion';

//Assets
import mitBanner from './images/mitBanner.jpg';
import indexpage01 from './images/indexpage01.jpg';
import indexPageVideo from './assets/video/indexPageVideo.mp4'
import mobileIndex from './images/mobile_index.jpg';

import {useHistory} from 'react-router-dom';


const transition = { duration: 0.6, ease: [0.6, 0.01, -0.05, 0.9]};

const titleAnimation = {
    initial: {
        y: 0,
    },
    animate: {
        transition: {
          delayChildren: 0.2,
          staggerChildren: 0.05,
          staggerDirection: 1,
        },
      },
    };


const letter = {
    intial: {
        y: 400,
    },
    animate: {
        y: 0,
        transition: {duration: 1,...transition}
    }
}



const ProjectDetail1 = React.memo(() => {
    

    const history = useHistory();
    //Exit Detail
    const exitDetailHandler = (e) => {
        const element = e.target;
        if(element.classList.contains('shadow')){
            document.body.style.overflow = 'auto';
            history.push('/');
        }
    };


    return (
        <>
            <CardShadow 
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                className="shadow" 
                onClick={exitDetailHandler}>
                <Detail 
                    initial='intial'
                    animate='animate'
                    exit='exit'
                    >
                    <div className='Page_top'>
                        <DetaiProject>
                        <ProjectWork 
                            initial={{opacity: 0, y:-20}}
                            animate={{
                                opacity: 1, 
                                y:0, 
                                transition: {delay: 1.2, ...transition}}}>
                            <span>UI Design</span>
                            <span>Micro-interactions</span>
                        </ProjectWork>    
                        <Close 
                            initial={{opacity: 0, y:-20}} 
                            animate={{
                                opacity: 1, 
                                y:0, 
                                transition: {delay: 1.2, ...transition}}}
                            className="shadow"
                            onClick={exitDetailHandler}
                            >[ close ]</Close>
                        </DetaiProject>
                        <ProjectTitle>
                            <motion.span variants={titleAnimation} className='Page_first'>
                                <motion.span variants={letter} >M</motion.span>
                                <motion.span variants={letter} >I</motion.span>
                                <motion.span variants={letter} >T</motion.span>
                                <motion.span variants={letter} >&nbsp;</motion.span>
                                <motion.span variants={letter} >&nbsp;</motion.span>
                                <motion.span variants={letter} >I</motion.span>
                                <motion.span variants={letter} >n</motion.span>
                                <motion.span variants={letter} >d</motion.span>
                                <motion.span variants={letter} >e</motion.span>
                                <motion.span variants={letter} >x</motion.span>
                                <motion.span variants={letter} >&nbsp;</motion.span>
                                <motion.span variants={letter} >p</motion.span>
                                <motion.span variants={letter} >a</motion.span>
                                <motion.span variants={letter} >g</motion.span>
                                <motion.span variants={letter} >e</motion.span>
                            </motion.span>
                        </ProjectTitle>
                    </div>
                    <ImageContainer>
                        <motion.div 
                            className='thumbnail-single'>
                            <div className='frame-single'>
                            <motion.img
                                initial={{ scale: 1 }}
                                src={mitBanner} 
                                alt='Banner of M.I.T. project' />
                            </div>
                        </motion.div>
                    </ImageContainer>
                    <Description>
                        <div className='PageS_container'>
                            <div className='PageS_row'>
                                <h2 className='title'>
                                Business Requirement
                                </h2>
                                <p className="Page_P">
                                The company’s market research team did a deep analysis of how effective their strategy was for selling individual courses through paid media efforts. The results of which indicated a clear gap in the market through which course sales might be more effective: it was clear that the company should pursue the idea of selling courses from a listing/index point of view.

                                They were essentially competing against themselves by bidding on the same search term across various search accounts. By grouping multiple courses that belong to the same vertical on one page, it allowed them to market more efficiently. 
                                </p>
                            </div>
                        </div>
                    </Description>
                    <FeauturedImagesB>
                        <div className="featured_images_container_b">
                            <img src={indexpage01} alt="design of M.I.T. project" />
                        </div>
                    </FeauturedImagesB>
                    <Description>
                        <div className='PageS_container'>
                            <div className='PageS_row'>
                                <h2 className='title'>
                                Purpose
                                </h2>
                                <p className="Page_P">
                                    The capacity to put multiple course combinations on one page and easily change these as required. For example, to group products via categories, partner products or in-house brand collections. This strategy also allowed them to facilitate market trends quite easily and reach a wider audience with a more generalized offering from these verticals.
                                </p>
                            </div>
                        </div>
                    </Description>
                    <FeauturedImagesD>
                        <div className="featured_images_container_c">
                            <div className="mobile_container">
                                <img src={mobileIndex} alt="mobile design of M.I.T. project" />
                            </div>
                        </div>
                    </FeauturedImagesD>
                    <Description>
                        <div className='PageS_container'>
                            <div className='PageS_row'>
                                <h2 className='title'>
                                Solution
                                </h2>
                                <p className="Page_P">
                                    Although the requirement was straightforward and, in theory, easy to achieve, there were nonetheless quite a few design challenges. A front-end was required to drive engagement to product offerings, but since it was also required to be flexible and agile, components were designed to be branded and adjusted accordingly, depending on whether they were for a specific brand, category or vertical. <br/><br/>Mobile was the target device as advertisements were rendered through social media platforms. This necessitated the design to be effective and aligned with the predetermined KPIs. 
                                    Here are some of the design choices made to align not only with the business requirement, but also with user demands and experiences. 

                                    <br /><br/>For mobile, I designed it in a way so that part of the CTAs (call-to-action) course cards would appear above the fold so that the user can instantly see the product offerings without having to scroll. The design would also show part of the next course card (in accordance with the Gestalt Continuity Law) to indicate that there is more content to follow. <br/>The user journey indicated that most users would arrive via paid media, so I wanted to keep the design clean and noise-free in order to direct the user to the content they’re after without them having to wade through clutter. 

                                    <br/><br/>When it comes to desktop, I tend to focus on ‘hierarchy’ which, for me, is one of the most important design principles. It basically entails three stages: to Attract, Intrigue, and finally, to deliver the Message. I therefore designed the landing page animation to pique the user’s interest and draw their attention with the course offerings (course cards), and added elegant hover states to intrigue them. Below these I positioned the copy strategically to deliver the message. 

                                    <br/><br/>Good use of negative space is also important, as it adds a sense of order and organization, and gives the design and the copy more breathing room.
                                </p>
                            </div>
                        </div>
                    </Description>
                    <FeauturedVideo01>
                    <div className="featured_video_container">
                        <video 
                            loop
                            autoPlay
                            muted
                            playsInline
                            src={indexPageVideo} >
                        </video> 
                    </div> 
                    </FeauturedVideo01>
                    <Description>
                        <div className='PageS_container'>
                            <div className='PageS_row'>
                                <h2 className='title'>
                                </h2>
                                <p className="Page_P">
                                    As a last touch, I used the contrast of MIT’s brand color on the white background for the scroll indicator. With a strong contrast combined with motion, it would grab the user’s eye, with the design indicating that there is more content below.

                                    <br/><br/>For me, design is not just about aesthetics but also about efficacy. It can be pretty to look at, but if it doesn’t enhance and streamline the user’s experience, it’s not a effective design.
                                </p>
                            </div>
                        </div>
                    </Description>
                    <div className="mobile-btm"></div>
                </Detail>
            </CardShadow >
        </>
    );
});

const CardShadow = styled(motion.div)`
    width: 100%;
    min-height: 100vh;
    overflow-y: scroll;
    background: rgba(0,0,0,0.5);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;
`;

const Detail = styled(motion.div)`
    width: 80%;
    border-radius: 0.2rem;
    //padding: 2rem 8rem;
    background: #DFDFDF;
    position: absolute;
    left: 10%;
    color: $black;
    @media (max-width: 1237px) {
        width: 84%;
        left: 8%;
        }
    @media (max-width: 500px) {
        width: 96%;
        top: 6%;
        left: 2%;
        }
    img {
        width: 100%;
    }
    .mobile-btm {
        @media (max-width: 435px) {
            padding-bottom: 8rem;
        }
    }
`;

const DetaiProject = styled(motion.div)`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0rem 8rem;
    padding-top: 2rem;
    @media (max-width: 1237px) {
        padding: 1rem 4rem;
        }
    @media (max-width: 500px) {
        padding: 1rem 1rem;
        }
`;

const Close =styled(motion.div)`
      cursor:pointer;
      font-size: 12px;
      letter-spacing: 0.3px;
`;

const ProjectWork = styled(motion.div)`
    color:#544f47;
    @media (max-width: 1085px) {
        font-size: 12px;
        }
    span:nth-child(2) {
        margin-left: 16px;
        }
`;

const ProjectTitle = styled(motion.div)`
    overflow: hidden;
    padding: 0rem 8rem;
    display: flex;
    justify-content: center;
    @media (max-width: 1085px) {
        padding: 0rem 4rem;
        }
    @media (max-width: 500px) {
        padding: 0rem 2rem;
        }
        .Page_first {
        margin-top: 24px;
        @media (max-width: 1085px) {
            margin-top: 4px;
            }
        }
        span {
        display: inline-block;
        position: relative;
        font-size: 100px;
        @media (max-width: 1341px) {
            font-size: 90px;
            }
        @media (max-width: 1237px) {
            font-size: 80px;
            }
        @media (max-width: 1085px) {
            font-size: 63px;
            }
        @media (max-width: 768px) {
            font-size: 35px;
            }
        @media (max-width: 500px) {
            font-size: 43px;
            }
        @media (max-width: 425px) {
            font-size: 36px;
            }
        }
`;

const ImageContainer = styled(motion.div)`
    .thumbnail-single {
        width: 100%;
        //height: 800px;
        margin: 0 auto;
        overflow: hidden;
        //position: absolute;
        left: 0;
        right: 0;
        .frame-single {
        img {
            position: relative;
            width: 100%;
            height: 400px;
            object-fit: cover;
            @media (max-width: 1085px) {
                height: 320px;
                }
            @media (max-width: 768px) {
                height: 280px;
                }
            @media (max-width: 500px) {
                height: 240px;
                }
            }
        }
    }
`;


const Description = styled(motion.div)`
    margin: 5rem 0rem;
    padding: 40 32px;
    @media (max-width: 425px) {
        margin: 2rem 0rem;
        }
    .PageS_container {
        padding: 0rem 8rem;
        @media (max-width: 1085px) {
            padding: 0rem 6rem;
        }
        @media (max-width: 768px) {
            padding: 0rem 4rem;
        }
        @media (max-width: 500px) {
            padding: 0rem 2rem;
        }
    .PageS_row {
        display: flex;
        justify-content: space-between;
        @media (max-width: 768px) {
            flex-direction: column;
        }
        .title {
            font-size: 1.2rem;
            font-weight: 400;
            letter-spacing: 0.5px;
            width: 35%;
            @media (max-width: 768px) {
                    width: 100%;
                    padding-bottom: 24px;
                    }
        }
        .Page_P{
            right: 0;
            overflow: hidden;
            width: 65%;
            font-size: 1rem;
            font-family: "Graphik-Regular";
            line-height: 1.9rem;
            color:#544f47;
            @media (max-width: 768px) {
                width: 100%;
                }
            @media (max-width: 425px) {
                font-size: 14px;
                line-height: 2;
          }
        }
    }
    }
`;


const FeauturedVideo01 = styled(motion.div)`
    margin: 0 auto;
    padding: 0rem 8rem;
    position: relative;
    overflow: hidden;
    @media (max-width: 1085px) {
        padding: 0rem 6rem;
        }
    @media (max-width: 768px) {
        padding: 0rem 4rem;
        }
    @media (max-width: 500px) {
        padding: 0rem 2rem;
        }
    //test
    @media (max-width: 425px) {
        padding: 0rem 0rem;
        }
    .featured_video_container{
        display: block;
        width: 100%;
        overflow: hidden;
        height: 500px;
        display: flex;
        align-items: center;
        //margin-bottom: 80px;
        @media (max-width: 1085px) {
            height: 321px;
            }
        @media (max-width: 768px) {
            height: 261px;
            }
        @media (max-width: 500px) {
            height: 210px;
            }
        video {
        object-fit: cover;
        position: relative;
        width: 100%;
        }
    }
`

const FeauturedImagesB = styled(motion.div)`
    margin: 0 auto;
    padding: 0rem 8rem;
    position: relative;
    overflow: hidden;
    @media (max-width: 1085px) {
        padding: 0rem 6rem;
        }
    @media (max-width: 768px) {
        padding: 0rem 4rem;
        }
    @media (max-width: 500px) {
        padding: 0rem 2rem;
        }
     //test
    @media (max-width: 425px) {
        padding: 0rem 0rem;
        }
    .featured_images_container_b{
        display: block;
        width: 100%;
        overflow: hidden;
        height: 500px;
        display: flex;
        align-items: center;
        @media (max-width: 1085px) {
            height: 321px;
            }
        @media (max-width: 768px) {
            height: 261px;
            }
        @media (max-width: 500px) {
            height: 210px;
            }
        img{
           object-fit: cover;
           position: relative;
        }
    }
`;

const FeauturedImagesD = styled(motion.div)`
    margin: 0 auto;
    padding: 0rem 8rem;
    position: relative;
    overflow: hidden;
    @media (max-width: 1085px) {
        padding: 0rem 6rem;
        }
    @media (max-width: 768px) {
        padding: 0rem 4rem;
        }
    @media (max-width: 500px) {
        padding: 0rem 2rem;
        }
     //test
    @media (max-width: 425px) {
        padding: 0rem 0rem;
        }
    .featured_images_container_c{
        display: block;
        width: 100%;
        overflow: hidden;
        height: 500px;
        display: flex;
        align-items: center;
        background: rgb(103,103,109);
        background: linear-gradient(315deg, rgba(103,103,109,1) 0%, rgba(53,50,57,1) 100%);
        .mobile_container{
            height: 462px;
            width: 187px;
            overflow: hidden;
            position: absolute;
            left: 50%;
            top: 50%;
            -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
            box-shadow: 0 70px 100px -35px rgb(0 0 0 / 70%);
            img{
            object-fit: cover;
            position: relative;
            width: 100%;
            top: 0;
            }
        }
    }
`;


export default ProjectDetail1;