import React from 'react';
// Styling and Animation
import styled from 'styled-components';
import { motion } from 'framer-motion';

//Assets
import careernavBanner from './images/careernavBanner02.jpg';
import careernav01 from './images/careernav01.jpg';
import careernav02 from './images/careernav02.jpg';


import {useHistory} from 'react-router-dom';


const transition = { duration: 0.6, ease: [0.6, 0.01, -0.05, 0.9]};

const titleAnimation = {
    initial: {
        y: 0,
    },
    animate: {
        transition: {
          delayChildren: 0.2,
          staggerChildren: 0.05,
          staggerDirection: 1,
        },
      },
    };


const letter = {
    intial: {
        y: 400,
    },
    animate: {
        y: 0,
        transition: {duration: 1,...transition}
    }
}


const ProjectDetail2 = React.memo(() => {
    

    const history = useHistory();
    //Exit Detail
    const exitDetailHandler = (e) => {
        const element = e.target;
        if(element.classList.contains('shadow')){
            document.body.style.overflow = 'auto';
            history.push('/');
        }
    };


    return (
        <>
            <CardShadow 
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                className="shadow" 
                onClick={exitDetailHandler}>
                <Detail 
                    initial='intial'
                    animate='animate'
                    exit='exit'
                    >
                    <div className='Page_top'>
                        <DetaiProject>
                        <ProjectWork 
                            initial={{opacity: 0, y:-20}}
                            animate={{
                                opacity: 1, 
                                y:0, 
                                transition: {delay: 1.2, ...transition}}}>
                            <span>UI / UX Design</span>
                        </ProjectWork>    
                        <Close 
                            initial={{opacity: 0, y:-20}} 
                            animate={{
                                opacity: 1, 
                                y:0, 
                                transition: {delay: 1.2, ...transition}}}
                            className="shadow"
                            onClick={exitDetailHandler}
                            >[ close ]</Close>
                        </DetaiProject>
                        <ProjectTitle>
                            <motion.span variants={titleAnimation} className='Page_first'>
                                <motion.span variants={letter} >C</motion.span>
                                <motion.span variants={letter} >a</motion.span>
                                <motion.span variants={letter} >r</motion.span>
                                <motion.span variants={letter} >e</motion.span>
                                <motion.span variants={letter} >e</motion.span>
                                <motion.span variants={letter} >r</motion.span>
                                <motion.span variants={letter} >&nbsp;</motion.span>
                                <motion.span variants={letter} >&nbsp;</motion.span>
                                <motion.span variants={letter} >N</motion.span>
                                <motion.span variants={letter} >a</motion.span>
                                <motion.span variants={letter} >v</motion.span>
                                <motion.span variants={letter} >i</motion.span>
                                <motion.span variants={letter} >g</motion.span>
                                <motion.span variants={letter} >a</motion.span>
                                <motion.span variants={letter} >t</motion.span>
                                <motion.span variants={letter} >o</motion.span>
                                <motion.span variants={letter} >r</motion.span>
                            </motion.span>
                        </ProjectTitle>
                    </div>
                    <ImageContainer>
                        <motion.div 
                            className='thumbnail-single'>
                            <div className='frame-single'>
                            <motion.img
                                initial={{ scale: 1 }}
                                src={careernavBanner} 
                                alt='Banner of career navigator' />
                            </div>
                        </motion.div>
                    </ImageContainer>
                    <Description>
                        <div className='PageS_container'>
                            <div className='PageS_row'>
                                <h2 className='title'>
                                Business Requirement
                                </h2>
                                <p className="Page_P">
                                The company’s mission is to provide information to prospective students to help them further their careers, but it's also important for the business to stay relevant and to explore various avenues for profit. One of these avenues was course suggestions, in other words, upselling courses, but the challenge was to upsell to the right audience. 
                                </p>
                            </div>
                        </div>
                    </Description>
                    <FeauturedImagesB>
                        <div className="featured_images_container">
                            <img src={careernav01} alt="design of the career navigator" />
                        </div>
                    </FeauturedImagesB>
                    <Description>
                        <div className='PageS_container'>
                            <div className='PageS_row'>
                                <h2 className='title'>
                                Purpose
                                </h2>
                                <p className="Page_P">
                                    As an education-technology company the main focus is to provide relevant and reliable information, whether that be through course material, blog articles, research reports or bespoke information hubs. <br/><br/>With the business requirement in mind, the market research team discovered a way not only to have the business benefit from the latest initiative but to actually help people navigate their careers. The idea was to provide accurate information based on a particular person's career needs. This information had to be choreographed with various career paths in mind. The information delivered could in effect help a person with decision making and suggest courses to assist or supplement their direction. The project was thus born and dubbed the “Career Navigator”
                                </p>
                            </div>
                        </div>
                    </Description>
                    <FeauturedImagesB>
                        <div className="featured_images_container_b">
                            <img src={careernav02} alt="another design of the career navigator"/>
                        </div>
                    </FeauturedImagesB>
                    <Description>
                        <div className='PageS_container'>
                            <div className='PageS_row'>
                                <h2 className='title'>
                                Solution
                                </h2>
                                <p className="Page_P">
                                At this point we had an inkling of how the information would be delivered to the user, but we had to solve the entry funnel, in other words, how users would access the information. We then developed a set of questions to help the platform understand the “where”, “what” and “how” of each user. These questions had to be completed through a dynamic input form which would render the relevant data.
                                <br/><br/>For instance, the user would select “I want to change careers” and the platform would then dynamically render supporting question input fields such as “Your current occupation” and “Your desired career”. This would allow us to garner accurate information based on the user's input. 

                                <br/><br/>Since it would have taken us years of research and planning, the team decided to use an API to provide the raw data, which we had to curate on the front-end. The challenge here was to distill the information so that it could be interpreted easily. The platform was designed to display information such as average salary based on experience. It also displayed required skills to fulfill a specific salary gap or job title, etc. 

                                <br/><br/>To meet the business’ requirement for upselling relevant courses, the skill gap rendered within a user's scenario triggered a list of all relevant courses in support of those skills. This allowed the business to stay true to its principles by rewarding the user with the benefit of a successful registration.

                                <br/><br/>As for the design, I asked myself how I could have the design indicate that, when comparing “A” and “B” in a certain instance, they also have a relationship, but also that all the “A’s” share a relationship, as well as all the “B’s”. I turned to proximity and repetition to provide the design solution.

                                <br/><br/>My focus here was on the proximity of design elements. Proximity indicates that design elements are linked in some way or have a relationship. This would help my design to provide certain cues and information to the user by grouping certain elements together. 

                                <br/><br/>Besides focusing on proximity, I wanted it to go hand in hand with effective repetition. As this tool would be a comparing utility, I used repetition in colors to indicate the two different job titles the user would compare. For example, the grey/white colors would indicate the one selected job title, and the pink colors the other. This groups information together, helping the user to better understand the interface. I also made use of the company’s brand colors, as I had to stay true to their style guide.  
                                </p>
                            </div>
                        </div>
                    </Description>
                    <div className="mobile-btm"></div>
                </Detail>
            </CardShadow >
        </>
    );
});

const CardShadow = styled(motion.div)`
    width: 100%;
    min-height: 100vh;
    overflow-y: scroll;
    background: rgba(0,0,0,0.5);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;
`;

const Detail = styled(motion.div)`
    width: 80%;
    border-radius: 0.2rem;
    //padding: 2rem 8rem;
    background: #DFDFDF;
    position: absolute;
    left: 10%;
    color: $black;
    @media (max-width: 1237px) {
        width: 84%;
        left: 8%;
        }
    @media (max-width: 500px) {
        width: 96%;
        top: 6%;
        left: 2%;
        }
    img {
        width: 100%;
    }
    .mobile-btm {
        @media (max-width: 435px) {
            padding-bottom: 8rem;
        }
    }
`;

const DetaiProject = styled(motion.div)`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0rem 8rem;
    padding-top: 2rem;
    @media (max-width: 1237px) {
        padding: 1rem 4rem;
        }
    @media (max-width: 500px) {
        padding: 1rem 1rem;
        }
`;

const Close =styled(motion.div)`
      cursor:pointer;
      font-size: 12px;
      letter-spacing: 0.3px;
`;

const ProjectWork = styled(motion.div)`
    color:#544f47;
    @media (max-width: 1085px) {
        font-size: 12px;
        }
    span:nth-child(2) {
        margin-left: 16px;
        }
`;

const ProjectTitle = styled(motion.div)`
    overflow: hidden;
    padding: 0rem 8rem;
    display: flex;
    justify-content: center;
    @media (max-width: 1085px) {
        padding: 0rem 4rem;
        }
    @media (max-width: 500px) {
        padding: 0rem 2rem;
        }
        .Page_first {
        margin-top: 24px;
        @media (max-width: 1085px) {
            margin-top: 4px;
            }
        }
        .Page_last {
        margin-top: 24px;
        @media (max-width: 1085px) {
            margin-top: 4px;
            }
        }
        span {
        display: inline-block;
        position: relative;
        font-size: 100px;
        @media (max-width: 1341px) {
            font-size: 90px;
            }
        @media (max-width: 1237px) {
            font-size: 80px;
            }
        @media (max-width: 1085px) {
            font-size: 63px;
            }
        @media (max-width: 768px) {
            font-size: 35px;
            }
        @media (max-width: 500px) {
            font-size: 43px;
            }
        @media (max-width: 425px) {
            font-size: 36px;
            }
        }
`;

const ImageContainer = styled(motion.div)`
    .thumbnail-single {
        width: 100%;
        //height: 800px;
        margin: 0 auto;
        overflow: hidden;
        //position: absolute;
        left: 0;
        right: 0;
        .frame-single {
        img {
            position: relative;
            width: 100%;
            height: 400px;
            object-fit: cover;
            @media (max-width: 1085px) {
                height: 320px;
                }
            @media (max-width: 768px) {
                height: 280px;
                }
            @media (max-width: 500px) {
                height: 240px;
                }
            }
        }
    }
`;



const Description = styled(motion.div)`
    margin: 5rem 0rem;
    padding: 40 32px;
    @media (max-width: 425px) {
        margin: 2rem 0rem;
        }
    .PageS_container {
        padding: 0rem 8rem;
        @media (max-width: 1085px) {
            padding: 0rem 6rem;
        }
        @media (max-width: 768px) {
            padding: 0rem 4rem;
        }
        @media (max-width: 500px) {
            padding: 0rem 2rem;
        }
    .PageS_row {
        display: flex;
        justify-content: space-between;
        @media (max-width: 768px) {
            flex-direction: column;
        }
        .title {
            font-size: 1.2rem;
            font-weight: 400;
            letter-spacing: 0.5px;
            width: 35%;
            @media (max-width: 768px) {
                    width: 100%;
                    padding-bottom: 24px;
                    }
        }
        .Page_P{
            right: 0;
            overflow: hidden;
            width: 65%;
            font-size: 1rem;
            font-family: "Graphik-Regular";
            line-height: 1.9rem;
            color:#544f47;
            @media (max-width: 768px) {
                width: 100%;
                }
            @media (max-width: 425px) {
                font-size: 14px;
                line-height: 2;
            }
        }
    }
    }
`;


const FeauturedImagesB = styled(motion.div)`
    margin: 0 auto;
    padding: 0rem 8rem;
    position: relative;
    overflow: hidden;
    @media (max-width: 1085px) {
        padding: 0rem 6rem;
        }
    @media (max-width: 768px) {
        padding: 0rem 4rem;
        }
    @media (max-width: 500px) {
        padding: 0rem 2rem;
        }
    //test
    @media (max-width: 425px) {
        padding: 0rem 0rem;
        }
    .featured_images_container_b{
        display: block;
        width: 100%;
        overflow: hidden;
        height: 500px;
        display: flex;
        align-items: center;
        @media (max-width: 1085px) {
            height: 321px;
            }
        @media (max-width: 768px) {
            height: 261px;
            }
        @media (max-width: 500px) {
            height: 210px;
            }
        img{
           object-fit: cover;
           position: relative;
        }
    }
`;


export default ProjectDetail2;

