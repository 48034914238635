import React from 'react';
import Logo from '../components/logo.svg';


const BackgroundLogo = () => {
    return (
        <div className="backgroundLogo">
         <img src={Logo} alt='logo' />
        </div>
    )
};

export default BackgroundLogo; 