import React from 'react';
// Styling and Animation
import styled from 'styled-components';
import { motion } from 'framer-motion';

//Assets
import codeVideo from './assets/video/codeVideo.mp4';
import logos from './images/logos.png';


import {useHistory} from 'react-router-dom';


const transition = { duration: 0.6, ease: [0.6, 0.01, -0.05, 0.9]};

const titleAnimation = {
    initial: {
        y: 0,
    },
    animate: {
        transition: {
          delayChildren: 0.2,
          staggerChildren: 0.05,
          staggerDirection: 1,
        },
      },
    };


const letter = {
    intial: {
        y: 400,
    },
    animate: {
        y: 0,
        transition: {duration: 1,...transition}
    }
}


const CodeDetail = React.memo(() => {
    

    const history = useHistory();
    //Exit Detail
    const exitDetailHandler = (e) => {
        const element = e.target;
        if(element.classList.contains('shadow')){
            document.body.style.overflow = 'auto';
            history.push('/');
        }
    };


    return (
        <>
            <CardShadow 
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                className="shadow" 
                onClick={exitDetailHandler}>
                <Detail 
                    initial='intial'
                    animate='animate'
                    exit='exit'
                    >
                    <div className='Page_top'>
                        <DetaiProject>
                        <ProjectWork 
                            initial={{opacity: 0, y:-20}}
                            animate={{
                                opacity: 1, 
                                y:0, 
                                transition: {delay: 1.2, ...transition}}}>
                            <span>Front-end</span>
                        </ProjectWork>    
                        <Close 
                            initial={{opacity: 0, y:-20}} 
                            animate={{
                                opacity: 1, 
                                y:0, 
                                transition: {delay: 1.2, ...transition}}}
                            className="shadow"
                            onClick={exitDetailHandler}
                            >[ close ]</Close>
                        </DetaiProject>
                        <ProjectTitle>
                            <motion.span variants={titleAnimation} className='Page_first'>
                                <motion.span variants={letter} >D</motion.span>
                                <motion.span variants={letter} >e</motion.span>
                                <motion.span variants={letter} >v</motion.span>
                                <motion.span variants={letter} >e</motion.span>
                                <motion.span variants={letter} >l</motion.span>
                                <motion.span variants={letter} >o</motion.span>
                                <motion.span variants={letter} >p</motion.span>
                                <motion.span variants={letter} >m</motion.span>
                                <motion.span variants={letter} >e</motion.span>
                                <motion.span variants={letter} >n</motion.span>
                                <motion.span variants={letter} >t</motion.span>
                            </motion.span>
                        </ProjectTitle>
                    </div>
                    <ImageContainer>
                        <motion.div 
                            className='thumbnail-single'>
                            <div className='frame-single'>
                                <div className="featured_video_container">
                                    <video 
                                        loop
                                        autoPlay
                                        muted
                                        playsInline
                                        src={codeVideo} >
                                    </video>
                                </div>
                            </div>
                        </motion.div>
                    </ImageContainer>
                    <Description>
                        <div className='PageS_container'>
                        <div className='PageS_row'>
                            <h2 className='title'>
                                Coding my designs
                            </h2>
                            <p className="Page_P">
                                Although I have several years of experience in designing user-interfaces, I only started coding in 2019. As developers don’t always have the time to develop the animations and micro-interactions that I would design with my UI, I was driven to deliver the code myself in front-end languages with my prototypes. This also sped up the workflow for developers. My hand-off prototypes would still be in design software such as InVision, etc., but alongside these I would produce the code for some high-fidelity designs, hover states and animations. 

                             <br/><br/> I’m comfortable with HTML, CSS, SASS and some Javascript. For my animations I would use CSS keyframes, GSAP and Framer Motion. I also started learning ReactJS recently and the music player that features in the video above is a project I did in a React course by DevEd. I also developed my own portfolio in React.
                            </p>
                        </div>
                        </div>
                    </Description>
                    <FeauturedImagesB>
                        <div className="featured_images_container">
                            <img src={logos} alt="logos of coding languages"/>
                        </div>
                    </FeauturedImagesB>
                    <div className="mobile-btm"></div>
                </Detail>
            </CardShadow >
        </>
    );
});

const CardShadow = styled(motion.div)`
    width: 100%;
    min-height: 100vh;
    overflow-y: scroll;
    background: rgba(0,0,0,0.5);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;
`;

const Detail = styled(motion.div)`
    width: 80%;
    border-radius: 0.2rem;
    //padding: 2rem 8rem;
    background: #dfdfdf;
    position: absolute;
    left: 10%;
    color: $black;
    @media (max-width: 1237px) {
        width: 84%;
        left: 8%;
        }
    @media (max-width: 500px) {
        width: 96%;
        top: 6%;
        left: 2%;
        }
    img {
        width: 100%;
    }
    .mobile-btm {
        @media (max-width: 435px) {
            padding-bottom: 8rem;
        }
    }
`;

const DetaiProject = styled(motion.div)`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0rem 8rem;
    padding-top: 2rem;
    @media (max-width: 1237px) {
        padding: 1rem 4rem;
        }
    @media (max-width: 500px) {
        padding: 1rem 1rem;
        }
`;

const Close =styled(motion.div)`
      cursor:pointer;
      font-size: 12px;
      letter-spacing: 0.3px;
`;

const ProjectWork = styled(motion.div)`
    color:#544f47;
    @media (max-width: 1085px) {
        font-size: 12px;
        }
    span:nth-child(2) {
        margin-left: 16px;
        }
`;

const ProjectTitle = styled(motion.div)`
    overflow: hidden;
    padding: 0rem 8rem;
    display: flex;
    justify-content: center;
    @media (max-width: 1085px) {
        padding: 0rem 4rem;
        }
    @media (max-width: 500px) {
        padding: 0rem 2rem;
        }
        .Page_first {
        margin-top: 24px;
        @media (max-width: 1085px) {
            margin-top: 4px;
            }
        }
        .Page_last {
        margin-top: 24px;
        @media (max-width: 1085px) {
            margin-top: 4px;
            }
        }
        span {
        display: inline-block;
        position: relative;
        font-size: 100px;
        @media (max-width: 1341px) {
            font-size: 90px;
            }
        @media (max-width: 1237px) {
            font-size: 80px;
            }
        @media (max-width: 1085px) {
            font-size: 63px;
            }
        @media (max-width: 768px) {
            font-size: 35px;
            }
        @media (max-width: 500px) {
            font-size: 43px;
            }
        @media (max-width: 425px) {
            font-size: 36px;
            }
        }
`;

const ImageContainer = styled(motion.div)`
    .thumbnail-single {
        width: 100%;
        //height: 800px;
        margin: 0 auto;
        overflow: hidden;
        //position: absolute;
        left: 0;
        right: 0;
        .frame-single {
        video {
            position: relative;
            width: 100%;
            height: 530px;
            object-fit: cover;
            overflow: hidden;
            filter: brightness(0.9);
            @media (max-width: 1085px) {
                height: 320px;
                }
            @media (max-width: 768px) {
                height: 280px;
                }
            @media (max-width: 500px) {
                height: 240px;
                }
            }
        }
    }
`;


const Description = styled(motion.div)`
    margin: 5rem 0rem;
    padding: 40 32px;
    .PageS_container {
        padding: 0rem 8rem;
        @media (max-width: 1085px) {
            padding: 0rem 6rem;
        }
        @media (max-width: 768px) {
            padding: 0rem 4rem;
        }
        @media (max-width: 500px) {
            padding: 0rem 2rem;
        }
    .PageS_row {
        display: flex;
        justify-content: space-between;
        @media (max-width: 768px) {
            flex-direction: column;
        }
        .title {
            font-size: 1.2rem;
            font-weight: 400;
            letter-spacing: 0.5px;
            width: 35%;
            @media (max-width: 768px) {
                    width: 100%;
                    padding-bottom: 24px;
                    }
        }
        .Page_P{
            right: 0;
            overflow: hidden;
            width: 65%;
            font-size: 1rem;
            font-family: "Graphik-Regular";
            line-height: 1.9rem;
            color:#544f47;
            @media (max-width: 900px) {
                width: 100%;
                }
            @media (max-width: 425px) {
                font-size: 14px;
                line-height: 2;
                }
        }
    }
    }
`;


const FeauturedImagesB = styled(motion.div)`
    margin: 0 auto;
    padding-left: 8rem;
    padding-right: 8rem;
    padding-bottom: 5rem;
    position: relative;
    overflow: hidden;
    width: 90%;
    filter: brightness(1.4);
    @media (max-width: 1085px) {
        padding: 5rem 6rem;
        padding-top: 0;
        }
    @media (max-width: 768px) {
        padding: 5rem 4rem;
        padding-top: 0;
        }
    @media (max-width: 500px) {
        padding: 4rem 2rem;
        padding-top: 0;
        }
    .featured_images_container_b{
        display: block;
        overflow: hidden;
        //height: 500px;
        display: flex;
        align-items: center;
        //width: 70%;
        img{
           //width: 70%;
        }
    }
`;

export default CodeDetail;
