import React, {useContext, useEffect, useRef} from 'react';
import {Power3, Power2 } from 'gsap';
import gsap from 'gsap';
import { motion } from 'framer-motion';
import ScrollTrigger from 'gsap/ScrollTrigger';

import { Link } from "react-router-dom";

//Assets
import personal from './images/personal.jpg';
import CustomCursorContext from './context/CustomCursorContext';


const transition = { duration: 0.6, ease: [0.43, 0.13, 0.23, 0.96]};


const Section5 =  () => {


    let container = useRef(null)
    let imageshow = useRef(null)
    let imageReveal = useRef(null);


    const {setType} = useContext(CustomCursorContext);

    //Load detail handler
    const loadDetailHandler = () => {
    document.body.style.overflow = 'hidden'
    };

   gsap.registerPlugin(ScrollTrigger);
   const headlineFirstV5 = ".aboutTwo-content-line-innerV5"
   const headlineSecondV5 = ".aboutTwo-content-line-innerTwoV5";
   const contentPV5 = ".contentPV5";

   

    useEffect(() => {

        gsap.to (container, {
            autoAlpha: 1,
            duration: 0.6,
            scrollTrigger: {
                trigger: ".sectionV5",
                start: "70% 100%",
                //markers: "true",
                toggleActions: "play none none pause"
            }
        });

        gsap.to (imageReveal, {
            height: "0%",
            ease: Power2.easeInOut,
            transformOrigin: 'top center',
            duration: 1.4,
            //onComplete: () => setAnimeComplete(true),
            scrollTrigger: {
                trigger: ".sectionV5",
                start: "70% 100%",
                //markers: "true",
                toggleActions: "play none none pause"
            }
        })

        gsap.to (".stophover", {
            height: "0%",
            duration: 4,
            //onComplete: () => setAnimeComplete(true),
            scrollTrigger: {
                trigger: ".sectionV5",
                start: "70% 100%",
                //markers: "true",
                toggleActions: "play none none pause"
            }
        })

        gsap.to (".revealtext", {
            x:  "5.4rem",
            ease: Power2.easeInOut,

            duration: 1.4,
            scrollTrigger: {
                trigger: ".sectionV5",
                start: "70% 100%",
                //markers: "true",
                toggleActions: "play none none pause"
            }
        })

    })

    useEffect(() => {

        //Content Stagger
        gsap.from([headlineFirstV5, headlineSecondV5 , contentPV5], {
            y: (i, target) => {
                return target.classList.contains("contentPV5") ? 20 : 54;
            },
            opacity: (i, target) => {
                return target.classList.contains("contentPV5") ? 0 : 1;
            },
            duration: 1,
            ease: Power3.easeOut,
            stagger: 0.35,
            scrollTrigger: {
                trigger: ".sectionV5",
                start: "70% 100%",
                //markers: "true",
                toggleActions: "play none none pause"
            }
        });

         //Image Animation 
        
        gsap.from(imageshow, {
            scale: 1.6,
            ease: Power3.easeOut,
            duration: 2,
            scrollTrigger: {
                trigger: ".sectionV5",
                start: "70% 100%",
                toggleActions: "play none none pause"
            },
        });
        
        //setAnimeComplete(true)

    }, []);
    

    return (
        <div className="sectionV5" onClick={loadDetailHandler}>
            <div className="containerAboutV5">
                <div className="aboutTwo-innerV5">
                    <div className="section-images">
                            <div className="section-images-inner">
                                <Link to="/freelancing" style= {{ textDecoration: 'none' }} >
                                    <div className="containerTest">
                                        <>
                                            <div className="img-container" ref={el => container = el}>
                                                <div className="stophover"></div>
                                                <div className="blue" ref={el => imageReveal = el}></div>
                                                    <motion.img 
                                                        ref={el=> {imageshow =el}}
                                                        src={personal}
                                                        onMouseEnter= {()=>setType('view')}
                                                        onMouseLeave={()=>setType('default')}
                                                        whileHover={{ scale: 1.1 }} /*{animeComplete === true ? { scale: 1.1 } : ""}*/
                                                        transition={transition}
                                                        alt="Freelancing and personal projects"
                                                        />
                                            </div>
                                            <div 
                                                className="viewproject"
                                                onMouseEnter= {()=>setType('view')}
                                                onMouseLeave={()=>setType('default')}
                                                >VIEW PROJECT
                                                <div className="revealtext"></div>
                                            </div>
                                        </>
                                    </div>
                                </Link>
                            </div>
                        </div>
                    <div className="aboutTwo-contentV5">
                            <div className="aboutTwo-content-innerV5">
                                <h4>
                                    <div className="aboutTwo-content-lineV5">
                                        <div className="aboutTwo-content-line-innerV5">Freelancing</div>
                                    </div>
                                    <div className="aboutTwo-content-lineV5">
                                        <div className="aboutTwo-content-line-innerTwoV5">& Personal</div>
                                    </div>
                                </h4>
                                <p className="contentPV5">I’ve been living and breathing design for a number of years. Take a look at some of my earlier work here.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Section5;