import React, {useContext, useState} from 'react';
import logo from '../components/headerlogo.svg';
import styled from 'styled-components';

import CustomCursorContext from './context/CustomCursorContext';

import {motion} from 'framer-motion'; 


const Header = () => {

    const {setType} = useContext(CustomCursorContext);
    const [hovered, setHovered] = useState(false)
    
    
    return (
        <Nav className="nav">
            <div className="container">
                <div className='row v-center space-between'>
                    <img src={logo} alt="logo" width="107" height="47" />
                    <Contact
                        onHoverStart={() => setHovered(!hovered)}
                        onHoverEnd={() => setHovered(!hovered)}
                    >
                        <a
                            onMouseEnter={()=>setType('contact')}
                            onMouseLeave={()=>setType('default')} 
                            href="mailto:figo@fnaude.com"
                            >Let's talk
                        </a>
                        <Line
                            animate={{ width: hovered ? "0%" : "100%"}}
                            transition={{ duration: 0.6, ease: [0.6, 0.05, -0.01, 0.9]}}
                        />
                    </Contact>
                </div>
            </div>
        </Nav >
    );
}

const Nav = styled(motion.div)`
    position: fixed;
    width: 100%;
    height: 128px;
    z-index: 6;
    pointer-events: none;
    @media (max-width: 425px) {
        height: 96px;
        }
`;

const Contact = styled(motion.div)`
    text-decoration: none;
    a {
        color: #424243;
        text-decoration: none;
        white-space: nowrap;
        pointer-events: auto;
    }
`;

const Line = styled(motion.div)`
    background: #424243;
    width: 100%;
    position: relative;
    left: 0%;
    height: 2px;
    top: 8px;
    @media (max-width: 1300px){
        left: 0%;
    }
` ;


export default Header;