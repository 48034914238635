import React, {useContext, useEffect, useRef } from 'react';
import {Power3, Power2 } from 'gsap';
import gsap from 'gsap';
import { motion } from 'framer-motion';
import ScrollTrigger from 'gsap/ScrollTrigger';

import { Link } from "react-router-dom";

//Assets
import indexPages from './images/indexpages.jpg';
import CustomCursorContext from './context/CustomCursorContext';


const transition = { duration: 0.6, ease: [0.43, 0.13, 0.23, 0.96]};


const MyWork01 =  () => {

    let container = useRef(null)
    let imageshow = useRef(null)
    let imageReveal = useRef(null);


    const {setType} = useContext(CustomCursorContext);

    //Load detail handler
    const loadDetailHandler = () => {
    document.body.style.overflow = 'hidden'
    };


   gsap.registerPlugin(ScrollTrigger);
   const headlineFirstV3 = ".aboutTwo-content-line-innerV3"
   const headlineSecondV3 = ".aboutTwo-content-line-innerTwoV3";
   const contentPV3 = ".contentPV3";

   



    useEffect(() => {

        gsap.to (container, {
            autoAlpha: 1,
            duration: 0.6,
            scrollTrigger: {
                trigger: ".sectionV3",
                start: "70% 100%",
                //markers: "true",
                toggleActions: "play none none pause"
            }
        });

        gsap.to (imageReveal, {
            height: "0%",
            ease: Power2.easeInOut,
            transformOrigin: 'top center',
            duration: 1.4,
            //onComplete: () => setAnimeComplete(true),
            scrollTrigger: {
                trigger: ".sectionV3",
                start: "70% 100%",
                //markers: "true",
                toggleActions: "play none none pause"
            }
        })

        gsap.to (".stophover", {
            height: "0%",
            duration: 4,
            //onComplete: () => setAnimeComplete(true),
            scrollTrigger: {
                trigger: ".sectionV3",
                start: "70% 100%",
                //markers: "true",
                toggleActions: "play none none pause"
            }
        })

        gsap.to (".revealtext", {
            x:  "5.4rem",
            ease: Power2.easeInOut,

            duration: 1.4,
            scrollTrigger: {
                trigger: ".sectionV3",
                start: "70% 100%",
                //markers: "true",
                toggleActions: "play none none pause"
            }
        })

    })

    useEffect(() => {

        //Content Stagger
        gsap.from([headlineFirstV3, headlineSecondV3 , contentPV3], {
            y: (i, target) => {
                return target.classList.contains("contentPV3") ? 20 : 54;
            },
            opacity: (i, target) => {
                return target.classList.contains("contentPV3") ? 0 : 1;
            },
            duration: 1,
            ease: Power3.easeOut,
            stagger: 0.35,
            scrollTrigger: {
                trigger: ".sectionV3",
                start: "70% 100%",
                //markers: "true",
                toggleActions: "play none none pause"
            }
        });

         //Image Animation 
        
        gsap.from(imageshow, {
            scale: 1.6,
            ease: Power3.easeOut,
            duration: 2,
            scrollTrigger: {
                trigger: ".sectionV3",
                start: "70% 100%",
                toggleActions: "play none none pause"
            },
        });
        
        //setAnimeComplete(true)

    }, []);
    

    return (
        <div className="sectionV3" onClick={loadDetailHandler}>
            <div className="containerAboutV3">
                <div className="aboutTwo-innerV3">
                    <div className="section-images">
                            <div className="section-images-inner">
                                <Link to="/indexpages" style= {{ textDecoration: 'none' }} >
                                    <div className="containerTest">
                                        <>
                                            <div className="img-container" ref={el => container = el}>
                                                <div className="stophover"></div>
                                                <div className="blue" ref={el => imageReveal = el}></div>
                                                    <motion.img 
                                                        ref={el=> {imageshow =el}}
                                                        src={indexPages}
                                                        onMouseEnter= {()=>setType('view')}
                                                        onMouseLeave={()=>setType('default')}
                                                        whileHover={{ scale: 1.1 }} /*{animeComplete === true ? { scale: 1.1 } : ""}*/
                                                        transition={transition}
                                                        alt=" Project: M.I.T. index page"
                                                        />
                                            </div>
                                            <div 
                                                className="viewproject"
                                                onMouseEnter= {()=>setType('view')}
                                                onMouseLeave={()=>setType('default')}
                                                >VIEW PROJECT
                                                <div className="revealtext"></div>
                                            </div>
                                        </>
                                    </div>
                                </Link>
                            </div>
                        </div>
                    <div className="aboutTwo-contentV3">
                            <div className="aboutTwo-content-innerV3">
                                <h4>
                                    <div className="aboutTwo-content-lineV3">
                                        <div className="aboutTwo-content-line-innerV3">MIT</div>
                                    </div>
                                    <div className="aboutTwo-content-lineV3">
                                        <div className="aboutTwo-content-line-innerTwoV3">Index Pages</div>
                                    </div>
                                </h4>
                                <p className="contentPV3">Course pages that compiled multiple courses from the world’s most renowned universities.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default MyWork01;