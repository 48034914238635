import React, { useEffect, useState , useContext} from 'react';

//Styling and Animation
import styled from 'styled-components';
import { motion } from 'framer-motion';
import {Power3} from 'gsap';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import CustomCursorContext from './context/CustomCursorContext';




const accordionIds = [
    {
        id: 0,
        title: "Qualifications",
        results: [
          "Human Computer Interaction in UX Design - M.I.T. (USA)",
          "User Interface Design - Brainstation, Toronto (Canada)",
          "User Experience Design - University of Cape Town (RSA)",
          "Digital Marketing - University of Cape Town (RSA)",
          "Web Design - University of Cape Town (RSA)",
          "Brand Management - University of Cape Town (RSA)",
        ],
      },
      {
        id: 1,
        title: "Design Tools",
        results: [
          "Sketch",
          "InVision",
          "InVision Studio",
          "Framer",
          "Flinto",
          "Figma",
          "Adobe Photoshop",
        ],
      },
      {
        id: 2,
        title: "CMS & SaaS Applications",
        results: [
          "Wordpress",
          "Webflow",
        ],
      },
      {
        id: 3,
        title: "Development",
        results: [
          "HTML",
          "CSS",
          "Sass / SCSS",
          "Javascript",
          "React JS",
          "GSAP",
          "Framer Motion",
        ],
      },
]

const MoreAbout = () => {

    const [expanded, setExpanded] = useState (0)


    gsap.registerPlugin(ScrollTrigger);
    const headlineTitle = ".headerTitle"
    const paragrahText = ".paragrahText"
    const skills = ".skills"


    useEffect (() => {
        gsap.from([headlineTitle,paragrahText], {
            y: (i,target) => {
                return target.classList.contains("headlineTitle") ? 20 : 54;
            },
            opacity: 0,
            duration: 1,
            ease: Power3.easeOut,
            stagger: 0.3,
            scrollTrigger: {
                trigger: ".headerTitle",
                start: "40% 100%",
                //markers: "true",
                toggleActions: "play none none pause"
            },
        });
        }, []);

    useEffect (() => {
        gsap.from(skills, {
            y: (i,target) => {
                return target.classList.contains("skills") ? 20 : 54;
            },
            opacity: 0,
            duration: 1,
            ease: Power3.easeOut,
            scrollTrigger: {
                trigger: ".skills",
                start: "40% 100%",
                //markers: "true",
                toggleActions: "play none none pause"
            },
        });
        }, []);




    return (
      <AboutMe className="about-sec">
        <HomeAboutSection>
            <AboutContainer>
                <Flex>
                    <About>
                        <h2 className="headerTitle">
                            Experienced and qualified in creating highly effective user-focused experiences and interfaces across a variety of enviroments. 
                        </h2>
                        <p className="paragrahText">
                          I’m originally from Cape Town, South Africa, but am now permanently residing in Toronto, Canada. I have done various UI and UX work for a host of American tech companies, startups and agencies, from San Francisco to New York. For former Google employees to building prototypes that was shown at Y-Combinator.  
                          <br/>
                          Until recently I was the full-time Senior UI Designer at a publicly traded US company. I am skilled in wireframing, interactive prototypes, user flows and UI design, and I can also hand-code my UI animations in front-end languages. Ultimately, I am passionate about problem solving through design. 
                        </p>
                    </About>
                    <Skills className="skills">
                        <h3>Skills</h3>
                        {accordionIds.map((details, index) => (
                            <Accordion 
                                key={index} 
                                details={details} 
                                expanded={expanded}
                                setExpanded={setExpanded}
                            />
                        ))}
                    </Skills>
                </Flex>
            </AboutContainer>
        </HomeAboutSection>
      </AboutMe>
    )
}

const Accordion =React.memo(({details, expanded, setExpanded}) => {
    const {setType} = useContext(CustomCursorContext);
    const isOpen = details.id === expanded
    const [hovered, setHovered] = useState(false)
    return (
        <>
            <AccordionHeader 
                onClick={() => setExpanded(isOpen ? false : details.id)} 
                onHoverStart={() => setHovered(!hovered)}
                onHoverEnd={() => setHovered(!hovered)}
                onMouseEnter= {()=>setType('accordion')}
                onMouseLeave={()=>setType('default')}
                whileHover={{
                    color: !isOpen === "#424243" ? "#e3154d" : "#e3154d",
                  }}
            >
                <AccordionIcon
                  onMouseEnter= {()=>setType('accordion')}
                  onMouseLeave={()=>setType('default')}>
                <motion.span
                    animate={{rotate: isOpen || hovered ? 0 : 45, x: 3 }}
                    transition={{ duration: 0.2, ease: [0.6, 0.05, -0.01, 0.9] }}
                    ></motion.span>
                <motion.span
                    animate={{rotate: isOpen  || hovered ? 0 : -45, x: -3 }}
                    transition={{ duration: 0.2, ease: [0.6, 0.05, -0.01, 0.9] }}
                    ></motion.span>
                </AccordionIcon>
                {details.title}  
            </AccordionHeader>
            <AccordionContent key='content' 
                animate={{height: isOpen ? '100%' : '0'}}
                transition={{ duration: 0.8, ease: [0.6, 0.05, -0.01, 0.9] }}>
                {details.results.map((result, index) =>(
                    <span key={index}>{result}</span>
                ))}
            </AccordionContent>
            <Line />
        </>
    )
})

//About
const AboutMe = styled(motion.div)`
`


const HomeAboutSection = styled(motion.div)`
  margin-bottom: 320px;
  @media (max-width: 768px) {
    margin-bottom: 8rem;
  }
`

const AboutContainer = styled(motion.div) `
  flex-grow: 1;
  margin: 0 auto;
  position: relative;
  width: auto;
  height: 100%;
  padding: 0 32px;
  @media (min-width: 1024px) {
    max-width: 960px;
  }
  @media (min-width: 1216px) {
    max-width: 1152px;
  }
  @media (min-width: 1408px) {
    max-width: 1244px;
  }
`

const Flex = styled(motion.div)`
  position: relative;
  display: flex;
  align-items: flex-start;
  @media (max-width: 873px) {
    flex-direction: column;
  }

`

const About = styled(motion.div)`
  width: 100%;
  // width: 400px;
  @media (max-width: 873px) {
    margin-bottom: 4rem;
  }
  h2 {
    width: 60%;
    font-size: 2.3rem;
    font-weight: 600;
    color: $black;
    margin-bottom: 30px;
    @media (max-width: 873px) {
      width: 100%;
      }
    @media (max-width: 580px) {
      font-size: 1.5rem;
      width: 100%;
      }
    @media (max-width: 425px) {
      font-size: 1.8rem;
      width: 100%;
      }
    }
  p {
    font-family: "Graphik-Regular";
    max-width: 440px;
    font-size: 1rem;
    line-height: 1.9rem;
    color:#544f47;
    @media (max-width: 425px) {
      font-size: 14px;
      line-height: 2;
    }
  }
`

const Skills = styled(motion.div)`
  @media (max-width: 873px) {
      width: 100%;
      }
`

//Accordion
const AccordionHeader = styled(motion.div)`
  width: 100%;
  color: #424243;
  height: 32px;
  display: flex;
  align-items: center;
  font-weight: 400;
  font-size: 1.15rem;
  letter-spacing: 0.04rem;
  margin: 1.2rem 0rem;
  cursor:pointer;
`
//7B7B76
const AccordionIcon = styled(motion.div)`
  display: flex;
  align-items: center;
  height: 100%;
  margin-right: 8px;
  span {
    width: 16px;
    height: 4px;
    background:  #424243;
    transition: all 0.1s ease-in-out;
    cursor:pointer;
  }
`

const Line = styled(motion.div)`
    background: #b4b4b4;
    height: 0.1rem;
    width: 100%;
`

const AccordionContent = styled(motion.div)`
  overflow: hidden;
  padding-left: 40px;
  line-height: 1.3rem;
  font-family: "Graphik-Regular";
  span {
    width: 100%;
    margin: 8px 0;
    margin-bottom: 1.2rem;
    font-size: 0.875rem;
    color:#544f47;
    display: block;
  }
`


export default MoreAbout