import React, {useEffect} from 'react';


//Styling and Animation
import styled from 'styled-components';
import { motion } from 'framer-motion';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';


const SectionIndicator = () => {

    gsap.registerPlugin(ScrollTrigger);

    useEffect(() => {

        ScrollTrigger.create({
        trigger: ".main",
        onToggle: self => {
            if (self.isActive) {
                gsap.to('.sectionNumbers', { y: 0 , ease: [0.6, 0.01, -0.05, 0.9] })
            }
        }
        })
        
        ScrollTrigger.create({
        trigger: ".sectionV2",
        onToggle: self2 => {
            if (self2.isActive) {
                gsap.to('.sectionNumbers', { y: -35 , ease: [0.6, 0.01, -0.05, 0.9] })
            }
        }
        })
        
        ScrollTrigger.create({
        trigger: ".sectionV3",
        onToggle: self3 => {
            if (self3.isActive) {
                gsap.to('.sectionNumbers', { y: -70 , ease: [0.6, 0.01, -0.05, 0.9] })
            }
        }
        })
        
        ScrollTrigger.create({
        start: "-10% 100%",
        trigger: ".about-sec",
        onToggle: self => {
            if (self.isActive) {
                gsap.to('.sectionNumbers', { y: -105 , ease: [0.6, 0.01, -0.05, 0.9] })
            }
        }
        })
        
        ScrollTrigger.create({
        trigger: ".contact-me",
        start: "-30% 100%",
        onToggle: self5 => {
            if (self5.isActive) {
                gsap.to('.sectionNumbers', { y: -140 , ease: [0.6, 0.01, -0.05, 0.9] })
            }
        }
        })
        
        },[]);

    return (
        <IndicatorContainer>
            <div className="text-container">
                <ul>
                    <div className="sectionNumbers">
                        <li>Introduction</li>
                        <li>About</li>
                        <li>My Work</li>
                        <li>Skills</li>
                        <li>Contact</li>
                    </div>
                </ul>
            </div>
        </IndicatorContainer>
    )
};

const IndicatorContainer = styled(motion.div)`
    position: fixed;
    display: flex;
    align-items: center;
    z-index: 8;
    color: $black;
    top: 0;
    right: 0;
    height:100vh;
    pointer-events: none;
    .text-container {
        transform: rotate(-90deg);
        width:210px;
        right: 0;
        
        ul {
            list-style:none;
            line-height:35px;
            font-size: 12px;
            letter-spacing: 0.3px;
            height: 37px;
            overflow: hidden;
            text-align: center;
            opacity: 0.8;
            margin-top: 64px;
            @media ( max-width: 1262px){
                margin-top: 128px;
                }
        }

        @media ( max-width: 1023px){
        display: none;
        }
    }
`


export default SectionIndicator; 