import React , {useContext} from 'react';

//Styling and Animation
import styled from 'styled-components';
import { motion } from 'framer-motion';



import CustomCursorContext from './context/CustomCursorContext';

const Contact = () => {

  const {setType} = useContext(CustomCursorContext);

    return (
      <div className="contact-me">
        <ContactSection>
            <ContactContainer>
                <ContactText>
                    <h1>
                        <div className="line">
                            <span className="talk">Let's talk.</span>
                        </div>
                    </h1>
                    <div className="contact-p">
                        <p>
                            <span>Contact me at</span>
                        </p>
                        
                        <p>
                            <a
                              onMouseEnter= {()=>setType('contact')}
                              onMouseLeave={()=>setType('default')} 
                              href="mailto:figo@fnaude.com">figo@fnaude.com</a>
                        </p>
                    </div>
                </ContactText>
            </ContactContainer>
            <Copyright>© Naudé Designs 2021</Copyright>
        </ContactSection>
      </div>
    );
}

const ContactSection = styled(motion.div)`
  margin-bottom: 40px;
  position: relative;
`

const ContactContainer = styled(motion.div) `
  margin: 0 auto;
  padding: 0 32px;
  position: relative;
  width: auto;
  height: 100%;
  @media (min-width: 1024px) {
    max-width: 960px;
  }
  @media (min-width: 1216px) {
    max-width: 1152px;
  }
  @media (min-width: 1408px) {
    max-width: 1244px;
  }
`

const ContactText = styled(motion.div)`
  width: 100%;
  h1 {
    //width: 60%;
    font-size: 7rem;
    color: $black;
    margin-bottom: 48px;
    @media (max-width: 580px) {
      font-size: 3rem;
      margin-bottom: 40px;
    }
    @media (max-width: 425px) {
      font-size: 3rem;
      line-height: 2.4rem;
      margin-bottom: 40px;
      //test
      -webkit-text-stroke: 1px;
    }
  }
  .contact-p {
    p {
        font-size: 2.3rem;
        font-weight: 400;
        line-height: 3rem;
        color: $black;
        @media (max-width: 580px) {
        font-size: 1.5rem;
        line-height: 2rem;
        width: 100%;
        }
        @media (max-width: 425px) {
          font-size: 1.15rem;
          letter-spacing: 0.04rem;
          line-height: 1.8rem;
          width: 100%;
        }
        span {
            position: relative;
            }
        a {
            color: #424243;
            
        }
    }
  }
  
`
const Copyright = styled(motion.div)`
  width: 100%;
  padding-top: 160px;
  display: flex;
  justify-content: center;
  color: #424243;
  letter-spacing: 1px;
  opacity: 0.8;
  font-size: 12px;
  @media (max-width: 768px) {
    padding-top: 8rem;
  }
  
`



export default Contact;