import React , { useEffect } from "react";
import "../src/styles/app.scss";

import Home from "./home";

//Router
import { Switch, Route } from 'react-router-dom';




const App = () => {
  
  useEffect(() => {
    document.title = "Figo Naudé | UX UI"
  }, [])


  return (
    <>
      <div className="App">
          <Switch>
            <Route path={ "/"}>
              <Home />
            </Route>
          </Switch>
        </div>
    </>
  );
}

export default App;
