import React, { useEffect, useState }from "react";
import Hero from "./components/hero";
import IntroAnimation from "./components/introAnimation";
import BackgroundLogo from "./components/backgroundLogo";

//Effects
import gsap, { Power3 } from 'gsap';
import { Helmet } from "react-helmet";
//Pages

import ScrollTrigger from 'gsap/ScrollTrigger';
import About from './components/about';
import MyWork01 from "./components/MyWork01";
import MyWork02 from './components/MyWork02';
import MyWork03 from "./components/MyWork03";
import CodeSection from './components/codeSection';
import MoreAbout from './components/moreAbout';

import ProjectDetail1 from './components/projectDetail1';
import {Route} from "react-router-dom";
import Contact from "./components/contact";
import SectionIndicator from "./components/sectionIndicator";
import DesignDevelop from "./components/DesignDevelop";
import CustomCursor from "./components/customCursor";
import CustomCursorManager from "./components/context/manager";
import CodeDetail from "./components/codeDetail";
import ProjectDetail2 from "./components/projectDetail2";
import ProjectDetail3 from "./components/projectDetail3";
import Header from "./components/header";



//Intro Animation
const tl = gsap.timeline();

const homeAnimation = completeAnimation => {
  tl.from('.line .hello', 1.8, {
    y:100,
    ease: "power4.out",
    delay: 1,
    skewY: 9,
    stagger: {
      amount: 0.3
    }
  }).to('.line .hello', 1.6, {
    y:-100,
    ease: Power3.easeOut,
    delay: 0,
    opacity: 0,
  }).from('.line span', 1.8, {
    y:100,
    ease: "power4.out",
    delay: 0,
    skewY: 7,
    stagger: {
      amount: 0.3
    }
  }).to('.intro-overlay', 1.6, {
      scaleY: 0,
      delay: 0.2,
      ease: 'expo.inOut',
      transformOrigin: 'top center',
      stagger: 0.4,
      onComplete: () => document.querySelector('.content').classList.remove('is-loading')
    }).from('.hero-p-row', 1.3, {
      y: 20, 
      opacity: 0, 
      ease: [0.6, 0.01, -0.05, 0.9], 
      onComplete: completeAnimation,
      //onComplete: () => document.querySelector('.content').classList.remove('is-loading')
    }).to(".animatecolor", {
      color: "#a5a5a5",
      ease: 'expo.inOut',
      duration: 2,
    },"-=2.8")
    .from('.nav', 0.8, {
      y:-30,
      opacity:0,
      ease: "easeOut",
    }, "-=1.2")
    .from('.design-develop-container', 0.8, {
      x:-30,
      opacity:0,
      ease: "easeInOut",
    }).from('.text-container', 0.8, {
      x: 30,
      opacity:0,
      ease: "easeInOut",
    },"-=0.8")
    ;
}



const Home = () => {

  useEffect(() => {
    // prevents flashing
    gsap.to("body", 0, { css: { visibility: "visible" } });
  });


  const [animationComplete, setAnimationComplete] = useState(false);

  const completeAnimation = () => {
      setAnimationComplete(true)
  };

  useEffect(() => {
  //On load timeline
  homeAnimation(completeAnimation);
  

},[]);

//scroll effect
gsap.registerPlugin(ScrollTrigger);

useEffect(() => {
    gsap.to('.boxes', {
        scrollTrigger: {
            trigger: ".scroll-text",
            scrub: true,
            /*markers: true*/
        },
        xPercent: -180,
        ease: "none",
    })
},[]);

  return (
    <div className="Home">
        <Helmet>
          <title>Figo Naudé | UI UX</title>
          <meta name="title" content="Figo Naudé | UI UX"></meta>
          <meta name="description" content="Internationally experienced designer who specializes in multidisciplinary UI and UX designs for both mobile and web applications."></meta>

          <meta property="og:type" content="website" />
          <meta property="og:url" content="https://fnaude.com" />
          <meta property="og:title" content="Figo Naudé | UI UX" />
          <meta property="og:description" content="Internationally experienced designer who specializes in multidisciplinary UI and UX designs for both mobile and web applications." />
          <meta property="og:image" content="https://fnaude.com/webclip.jpg/" />

          <meta property="twitter:card" content="summary_large_image" />
          <meta property="twitter:url" content="https://fnaude.com/" />
          <meta property="twitter:title" content="Figo Naudé | UI UX" />
          <meta property="twitter:description" content="Internationally experienced designer who specializes in multidisciplinary UI and UX designs for both mobile and web applications." />
          <meta property="twitter:image" content="https://fnaude.com/webclip.jpg/"></meta>
        </Helmet>
        <div className="content is-loading">
        {animationComplete === false ? <IntroAnimation />:""}
        <CustomCursorManager>
            <CustomCursor />
            <Header />
            <Hero />
            <DesignDevelop />
            <SectionIndicator />
            <BackgroundLogo />
            <About />
            <Route path="/indexpages">
              <ProjectDetail1 />
            </Route>
            <MyWork01 />
            <Route path="/career_navigator">
              <ProjectDetail2 />
            </Route>
            <MyWork02 />
            <Route path="/freelancing">
              <ProjectDetail3 />
            </Route>
            <MyWork03 />
            <Route path="/coding">
                <CodeDetail />
            </Route>
            <CodeSection />
            <MoreAbout />
            <Contact />
        </CustomCursorManager>
      </div>
    </div>
  );
}

export default Home;
