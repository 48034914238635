import React, {useEffect} from 'react';
import {Power3} from 'gsap';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';


function About () {

   gsap.registerPlugin(ScrollTrigger);
   const headlineFirstV2 = ".aboutTwo-content-line-innerV2"
   const headlineSecondV2 = ".aboutTwo-content-line-innerTwoV2";
   const contentPV2 = ".contentPV2";

    useEffect(() => {
    gsap.from([headlineFirstV2, headlineSecondV2 , contentPV2], {
        y: (i, target) => {
            return target.classList.contains("contentPV2") ? 20 : 54;
          },
        opacity: (i, target) => {
            return target.classList.contains("contentPV2") ? 0 : 1;
          },
        duration: 1,
        ease: Power3.easeOut,
        stagger: 0.3,
        scrollTrigger: {
            trigger: ".sectionV2",
            start: "55% 100%",
            //markers: "true",
            toggleActions: "play none none pause"
        }
    });
    }, []);

    return (
        <div className="sectionV2">
            <div className="containerAboutV2">
                <div className="aboutTwo-innerV2">
                    <div className="aboutTwo-numberV2">
                    <div className="aboutTwo-contentV2">
                        <div className="aboutTwo-content-innerV2">
                            <h4>
                                <div className="aboutTwo-content-lineV2">
                                    <div className="aboutTwo-content-line-innerV2">Designing for better</div>
                                </div>
                                <div className="aboutTwo-content-lineV2">
                                    <div className="aboutTwo-content-line-innerTwoV2">user experiences.</div>
                                </div>
                            </h4>
                            <div className="aboutTwo-paragraph-innerV2" /*ref={el => content = el}*/>
                                <p className="contentPV2">As an advocate for the end user, it is my business to devise solutions for them and tying these in with business objectives. I have ample experience in solving complex data flows and simplifying processes to create impressive experiences. I also enjoy designing and developing complex animations and micro-interactions to help the user understand the interface even better.</p>
                            </div>
                        </div>
                    </div>  
                    </div>
                </div>
            </div>
            <div className="home-section">
                <div className="container">
                    <div className="scroll-text">
                    <div className="boxes">
                        Doing &nbsp; what &nbsp; I &nbsp; <span>love.</span>
                     </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default About;