import React from 'react';
// Styling and Animation
import styled from 'styled-components';
import { motion } from 'framer-motion';

//Assets
import personalBanner from './images/personalBanner.jpg';
import travel from './images/travel.gif';
import login from './images/login.gif';
import mobileMenu from './images/mobileMenu.gif';
import personal01 from './assets/video/personal01.mp4';
import wFPortfolio from './assets/video/wFPortfolio.mp4';

import {useHistory} from 'react-router-dom';



const transition = { duration: 0.6, ease: [0.6, 0.01, -0.05, 0.9]};

const titleAnimation = {
    initial: {
        y: 0,
    },
    animate: {
        transition: {
          delayChildren: 0.2,
          staggerChildren: 0.05,
          staggerDirection: 1,
        },
      },
    };

const letter = {
    intial: {
        y: 400,
    },
    animate: {
        y: 0,
        transition: {duration: 1,...transition}
    }
}



const ProjectDetail3 = React.memo(() => {
    

    const history = useHistory();
    //Exit Detail
    const exitDetailHandler = (e) => {
        const element = e.target;
        if(element.classList.contains('shadow')){
            document.body.style.overflow = 'auto';
            history.push('/');
        }
    };


    return (
        <>
            <CardShadow 
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                className="shadow" 
                onClick={exitDetailHandler}>
                <Detail 
                    initial='intial'
                    animate='animate'
                    exit='exit'
                    >
                    <div className='Page_top'>
                        <DetaiProject>
                        <ProjectWork 
                            initial={{opacity: 0, y:-20}}
                            animate={{
                                opacity: 1, 
                                y:0, 
                                transition: {delay: 1.2, ...transition}}}>
                            <span>UI / UX Design</span>
                        </ProjectWork>    
                        <Close 
                            initial={{opacity: 0, y:-20}} 
                            animate={{
                                opacity: 1, 
                                y:0, 
                                transition: {delay: 1.2, ...transition}}}
                            className="shadow"
                            onClick={exitDetailHandler}
                            >[ close ]</Close>
                        </DetaiProject>
                        <ProjectTitle>
                            <motion.span variants={titleAnimation} className='Page_first'>
                                <motion.span variants={letter} >F</motion.span>
                                <motion.span variants={letter} >r</motion.span>
                                <motion.span variants={letter} >e</motion.span>
                                <motion.span variants={letter} >e</motion.span>
                                <motion.span variants={letter} >l</motion.span>
                                <motion.span variants={letter} >a</motion.span>
                                <motion.span variants={letter} >n</motion.span>
                                <motion.span variants={letter} >c</motion.span>
                                <motion.span variants={letter} >i</motion.span>
                                <motion.span variants={letter} >n</motion.span>
                                <motion.span variants={letter} >g</motion.span>
                            </motion.span>
                        </ProjectTitle>
                    </div>
                    <ImageContainer>
                        <motion.div 
                            className='thumbnail-single'>
                            <div className='frame-single'>
                            <motion.img
                                initial={{ scale: 1 }}
                                src={personalBanner} 
                                alt='Banner of freelancing and personal projects' />
                            </div>
                        </motion.div>
                    </ImageContainer>
                    <Description>
                        <div className='PageS_container'>
                            <div className='PageS_row'>
                                <h2 className='title'>
                                Freelancing
                                </h2>
                                <p className="Page_P">
                                    I fell in love with Web Design in 2010, before UI and UX were common job titles in the field. But as digital products became more relevant to our everyday life, I became fascinated by user-centric design and the usability of digital products. 

                                   <br/><br/>As I became increasingly conscious of and attentive to user experiences, I decided to further my education in UX and UI design. After gaining the relevant qualifications, I made the switch from freelancing in web design, to freelancing in the UX/UI design field. I first started freelancing mostly on Upwork – I’ve listed some of my earlier work below. Due to Non-Disclosure Agreements (NDAs), I can't share all of my work but I’m more than happy to forward samples and discuss my process upon request.
                                </p>
                            </div>
                        </div>
                    </Description>
                    <FeauturedImages>
                        <div className="featured_images_container">
                            <img src={travel} alt="design of freelancing projects 1" />
                        </div>
                    </FeauturedImages>
                    <FeauturedImagesC>
                        <div className="featured_images_container_c">
                            <div className="mobile_container">
                                <img src={login} alt="design of freelancing projects 2" />
                            </div>
                        </div>
                    </FeauturedImagesC>
                    <FeauturedImagesD>
                        <div className="featured_images_container_c">
                            <div className="mobile_container">
                                <img src={mobileMenu} alt="design of freelancing projects 3" />
                            </div>
                        </div>
                    </FeauturedImagesD>
                    <Description>
                        <div className='PageS_container'>
                            <div className='PageS_row'>
                                <h2 className='title'>
                                Personal
                                </h2>
                                <p className="Page_P">
                                    I live by a motto of “constant improvement”, so I like to challenge myself occasionally in my free time with my UI, development or animations/micro-interactions. I feel genuinely lucky to be able to enjoy what I do and I hope some of my earlier personal projects reflect this. 
                                </p>
                            </div>
                        </div>
                    </Description>
                    <FeauturedVideo01>
                    <div className="featured_video_container">
                        <video 
                            loop
                            autoPlay
                            muted
                            playsInline
                            src={personal01} >
                        </video> 
                    </div> 
                    </FeauturedVideo01>
                    <FeauturedVideo01>
                    <div className="featured_video_container">
                        <video 
                            loop
                            autoPlay
                            muted
                            playsInline
                            src={wFPortfolio} >
                        </video> 
                    </div> 
                    </FeauturedVideo01>
                    <div className="mobile-btm"></div>
                </Detail>
            </CardShadow >
        </>
    );
});

const CardShadow = styled(motion.div)`
    width: 100%;
    min-height: 100vh;
    overflow-y: scroll;
    background: rgba(0,0,0,0.5);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;
`;

const Detail = styled(motion.div)`
    width: 80%;
    border-radius: 0.2rem;
    background: #DFDFDF;
    position: absolute;
    left: 10%;
    color: $black;
    @media (max-width: 1237px) {
        width: 84%;
        left: 8%;
        }
    @media (max-width: 500px) {
        width: 96%;
        top: 6%;
        left: 2%;
        }
    img {
        width: 100%;
    }
    .mobile-btm {
        @media (max-width: 435px) {
            padding-bottom: 8rem;
        }
    }
`;

const DetaiProject = styled(motion.div)`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0rem 8rem;
    padding-top: 2rem;
    @media (max-width: 1237px) {
        padding: 1rem 4rem;
        }
    @media (max-width: 500px) {
        padding: 1rem 1rem;
        }
`;

const Close =styled(motion.div)`
      cursor:pointer;
      font-size: 12px;
      letter-spacing: 0.3px;
`;

const ProjectWork = styled(motion.div)`
    color:#544f47;
    @media (max-width: 1085px) {
        font-size: 12px;
        }
    span:nth-child(2) {
        margin-left: 16px;
        }
`;

const ProjectTitle = styled(motion.div)`
    overflow: hidden;
    padding: 0rem 8rem;
    display: flex;
    justify-content: center;
    @media (max-width: 1085px) {
        padding: 0rem 4rem;
        }
    @media (max-width: 500px) {
        padding: 0rem 2rem;
        }
        .Page_first {
        margin-top: 24px;
        @media (max-width: 1085px) {
            margin-top: 4px;
            }
        }

        span {
        display: inline-block;
        position: relative;
        font-size: 100px;
        @media (max-width: 1341px) {
            font-size: 90px;
            }
        @media (max-width: 1237px) {
            font-size: 80px;
            }
        @media (max-width: 1085px) {
            font-size: 63px;
            }
        @media (max-width: 768px) {
            font-size: 35px;
            }
        @media (max-width: 500px) {
            font-size: 43px;
            }
        @media (max-width: 425px) {
            font-size: 36px;
            }
        }
`;

const ImageContainer = styled(motion.div)`
    .thumbnail-single {
        width: 100%;
        margin: 0 auto;
        overflow: hidden;
        left: 0;
        right: 0;
        .frame-single {
        img {
            position: relative;
            width: 100%;
            height: 400px;
            object-fit: cover;
            @media (max-width: 1085px) {
                height: 320px;
                }
            @media (max-width: 768px) {
                height: 280px;
                }
            @media (max-width: 500px) {
                height: 240px;
                }
            }
        }
    }
`;


const FeauturedImages = styled(motion.div)`
    margin: 0 auto;
    padding: 0rem 8rem;
    position: relative;
    overflow: hidden;
    @media (max-width: 1085px) {
        padding: 0rem 6rem;
        }
    @media (max-width: 768px) {
        padding: 0rem 4rem;
        }
    @media (max-width: 500px) {
        padding: 0rem 2rem;
        }
    //test
    @media (max-width: 425px) {
        padding: 0rem 0rem;
        }
    .featured_images_container{
        display: block;
        width: 100%;
        overflow: hidden;
        height: 500px;
        display: flex;
        align-items: center;
        margin-bottom: 80px;
        @media (max-width: 1085px) {
            height: 321px;
            }
        @media (max-width: 768px) {
            height: 261px;
            }
        @media (max-width: 500px) {
            height: 210px;
            }
        img{
           object-fit: cover;
           position: relative;
        }
    }
`;

const FeauturedVideo01 = styled(motion.div)`
    margin: 0 auto;
    padding: 0rem 8rem;
    position: relative;
    overflow: hidden;
    @media (max-width: 1085px) {
        padding: 0rem 6rem;
        }
    @media (max-width: 768px) {
        padding: 0rem 4rem;
        }
    @media (max-width: 500px) {
        padding: 0rem 2rem;
        }
    //test
    @media (max-width: 425px) {
        padding: 0rem 0rem;
        }
    .featured_video_container{
        display: block;
        width: 100%;
        overflow: hidden;
        height: 500px;
        display: flex;
        align-items: center;
        margin-bottom: 80px;
        @media (max-width: 1085px) {
            height: 321px;
            }
        @media (max-width: 768px) {
            height: 261px;
            }
        @media (max-width: 500px) {
            height: 210px;
            }
        video {
        object-fit: cover;
        position: relative;
        width: 100%;
        }
    }
`


const FeauturedImagesC = styled(motion.div)`
    margin: 0 auto;
    padding: 0rem 8rem;
    position: relative;
    overflow: hidden;
    margin-bottom: 80px;
    @media (max-width: 1085px) {
        padding: 0rem 6rem;
        }
    @media (max-width: 768px) {
        padding: 0rem 4rem;
        }
    @media (max-width: 500px) {
        padding: 0rem 2rem;
        }
    //test
    @media (max-width: 425px) {
        padding: 0rem 0rem;
        }
    .featured_images_container_c{
        display: block;
        width: 100%;
        overflow: hidden;
        height: 500px;
        display: flex;
        align-items: center;
        background: rgb(103,103,109);
        background: linear-gradient(315deg, rgba(103,103,109,1) 0%, rgba(53,50,57,1) 100%);
        .mobile_container{
            height: 401px;
            width: 185px;
            position: absolute;
            left: 50%;
            top: 50%;
            -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
            box-shadow: 0 70px 100px -35px rgb(0 0 0 / 70%);
            img{
            object-fit: cover;
            position: relative;
            height: 100%;
            width: 100%;
            }
        }
    }
`;

const FeauturedImagesD = styled(motion.div)`
    margin: 0 auto;
    padding: 0rem 8rem;
    position: relative;
    overflow: hidden;
    @media (max-width: 1085px) {
        padding: 0rem 6rem;
        }
    @media (max-width: 768px) {
        padding: 0rem 4rem;
        }
    @media (max-width: 500px) {
        padding: 0rem 2rem;
        }
    //test
    @media (max-width: 425px) {
        padding: 0rem 0rem;
        }
    .featured_images_container_c{
        display: block;
        width: 100%;
        overflow: hidden;
        height: 500px;
        display: flex;
        align-items: center;
        background: rgb(103,103,109);
        background: linear-gradient(315deg, rgba(103,103,109,1) 0%, rgba(53,50,57,1) 100%);
        .mobile_container{
            height: 401px;
            width: 185px;
            position: absolute;
            left: 50%;
            top: 50%;
            -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
            box-shadow: 0 70px 100px -35px rgb(0 0 0 / 70%);
            img{
            object-fit: cover;
            position: relative;
            height: 100%;
            width: 100%;
            }
        }
    }
`;

const Description = styled(motion.div)`
    margin: 5rem 0rem;
    padding: 40 32px;
    @media (max-width: 425px) {
        margin: 2rem 0rem;
        }
    .PageS_container {
        padding: 0rem 8rem;
        @media (max-width: 1085px) {
            padding: 0rem 6rem;
        }
        @media (max-width: 768px) {
            padding: 0rem 4rem;
        }
        @media (max-width: 500px) {
            padding: 0rem 2rem;
        }
    .PageS_row {
        display: flex;
        justify-content: space-between;
        @media (max-width: 768px) {
            flex-direction: column;
        }
        .title {
            font-size: 1.2rem;
            font-weight: 400;
            letter-spacing: 0.5px;
            width: 35%;
            @media (max-width: 768px) {
                    width: 100%;
                    padding-bottom: 24px;
                    }
        }
        .Page_P{
            right: 0;
            overflow: hidden;
            width: 65%;
            font-size: 1rem;
            font-family: "Graphik-Regular";
            line-height: 1.9rem;
            color:#544f47;
            @media (max-width: 768px) {
                width: 100%;
                }
            @media (max-width: 425px) {
                font-size: 14px;
                line-height: 2;
                }
        }
    }
    }
`;


export default ProjectDetail3;