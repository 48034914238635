import React, {useContext, useEffect, useRef} from 'react';
import {Power3, Power2 } from 'gsap';
import gsap from 'gsap';
import { motion } from 'framer-motion';
import ScrollTrigger from 'gsap/ScrollTrigger';

import { Link } from "react-router-dom";

//Assets
import careerNav from './images/careernav.jpg';
import CustomCursorContext from './context/CustomCursorContext';


const transition = { duration: 0.6, ease: [0.43, 0.13, 0.23, 0.96]};


const MyWork02 =  () => {


    let container = useRef(null)
    let imageshow = useRef(null)
    let imageReveal = useRef(null);


    const {setType} = useContext(CustomCursorContext);

    //Load detail handler
    const loadDetailHandler = () => {
    document.body.style.overflow = 'hidden'
    };


   gsap.registerPlugin(ScrollTrigger);
   const headlineFirstV4 = ".aboutTwo-content-line-innerV4"
   const headlineSecondV4 = ".aboutTwo-content-line-innerTwoV4";
   const contentPV4 = ".contentPV4";

   



    useEffect(() => {

        gsap.to (container, {
            autoAlpha: 1,
            duration: 0.6,
            scrollTrigger: {
                trigger: ".sectionV4",
                start: "70% 100%",
                //markers: "true",
                toggleActions: "play none none pause"
            }
        });

        gsap.to (imageReveal, {
            height: "0%",
            ease: Power2.easeInOut,
            transformOrigin: 'top center',
            duration: 1.4,
            //onComplete: () => setAnimeComplete(true),
            scrollTrigger: {
                trigger: ".sectionV4",
                start: "70% 100%",
                //markers: "true",
                toggleActions: "play none none pause"
            }
        })

        gsap.to (".stophover", {
            height: "0%",
            duration: 4,
            //onComplete: () => setAnimeComplete(true),
            scrollTrigger: {
                trigger: ".sectionV4",
                start: "70% 100%",
                //markers: "true",
                toggleActions: "play none none pause"
            }
        })

        gsap.to (".revealtext", {
            x:  "5.4rem",
            ease: Power2.easeInOut,

            duration: 1.4,
            scrollTrigger: {
                trigger: ".sectionV4",
                start: "70% 100%",
                //markers: "true",
                toggleActions: "play none none pause"
            }
        })

    })

    useEffect(() => {

        //Content Stagger
        gsap.from([headlineFirstV4, headlineSecondV4 , contentPV4], {
            y: (i, target) => {
                return target.classList.contains("contentPV4") ? 20 : 54;
            },
            opacity: (i, target) => {
                return target.classList.contains("contentPV4") ? 0 : 1;
            },
            duration: 1,
            ease: Power3.easeOut,
            stagger: 0.35,
            scrollTrigger: {
                trigger: ".sectionV4",
                start: "70% 100%",
                //markers: "true",
                toggleActions: "play none none pause"
            }
        });

         //Image Animation 
        
        gsap.from(imageshow, {
            scale: 1.6,
            ease: Power3.easeOut,
            duration: 2,
            scrollTrigger: {
                trigger: ".sectionV4",
                start: "70% 100%",
                toggleActions: "play none none pause"
            },
        });
        
        //setAnimeComplete(true)

    }, []);
    

    return (
        <div className="sectionV4" onClick={loadDetailHandler}>
            <div className="containerAboutV4">
                <div className="aboutTwo-innerV4">
                <div className="aboutTwo-contentV4">
                            <div className="aboutTwo-content-innerV4">
                                <h4>
                                    <div className="aboutTwo-content-lineV4">
                                        <div className="aboutTwo-content-line-innerV4">Career</div>
                                    </div>
                                    <div className="aboutTwo-content-lineV4">
                                        <div className="aboutTwo-content-line-innerTwoV4">Navigator</div>
                                    </div>
                                </h4>
                                <p className="contentPV4">An online utility that assists users in their careers and helps them decide which skills to hone in order to achieve their professional goals.</p>
                        </div>
                    </div>
                    <div className="section-images">
                            <div className="section-images-inner">
                                <Link to="/career_navigator" style= {{ textDecoration: 'none' }} >
                                    <div className="containerTest">
                                        <>
                                            <div className="img-container" ref={el => container = el}>
                                                <div className="stophover"></div>
                                                <div className="blue" ref={el => imageReveal = el}></div>
                                                    <motion.img 
                                                        ref={el=> {imageshow =el}}
                                                        src={careerNav}
                                                        onMouseEnter= {()=>setType('view')}
                                                        onMouseLeave={()=>setType('default')}
                                                        whileHover={{ scale: 1.1 }} /*{animeComplete === true ? { scale: 1.1 } : ""}*/
                                                        transition={transition}
                                                        alt="Project: career navigator"
                                                        />
                                            </div>
                                            <div 
                                                className="viewproject"
                                                onMouseEnter= {()=>setType('view')}
                                                onMouseLeave={()=>setType('default')}
                                                >VIEW PROJECT
                                                <div className="revealtext"></div>
                                            </div>
                                        </>
                                    </div>
                                </Link>
                            </div>
                        </div>
                </div>
            </div>
        </div>
    );
}

export default MyWork02;