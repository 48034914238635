import React from 'react';

//Styling and Animation
import styled from 'styled-components';
import { motion } from 'framer-motion';

const DesignDevelop = () => {

    return (
        <DesignDeveloped>
            <div className="design-develop-container">
                <div className="text-box">Design & Developed by Myself</div>
            </div>
        </DesignDeveloped>
    )
};

const DesignDeveloped = styled(motion.div)`
    position: fixed;
    display: flex;
    align-items: center;
    z-index: 8;
    color: $black;
    top: 0;
    left: 0;
    height:100vh;
    pointer-events: none;
    .design-develop-container {
        transform: rotate(-90deg);
        width:210px;
        .text-box{
            font-size: 12px;
            letter-spacing: 0.3px;
            height: 37px;
            overflow: hidden;
            text-align: center;
            margin-bottom: 64px;
            opacity: 0.8;
            @media ( max-width: 1262px){
                margin-bottom: 128px;
                }
        }
        @media ( max-width: 1023px){
        display: none;
        }
    }
`


export default DesignDevelop; 