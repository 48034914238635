import React, { useState, useEffect, useContext } from 'react';

//Styling and Animation
import styled from 'styled-components';
import { motion } from 'framer-motion';
import {Power3} from 'gsap';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';

import codeVideo from './assets/video/codeVideo.mp4';

import { Link } from "react-router-dom";
import CustomCursorContext from './context/CustomCursorContext';


const CodeSection = React.memo(() => {

  const {setType} = useContext(CustomCursorContext);
  const [hovered, setHovered] = useState(false)
    //Load detail handler
    const loadDetailHandler2 = () => {
        document.body.style.overflow = 'hidden'
        };

  gsap.registerPlugin(ScrollTrigger);  
  const VideoAnimate = ".videocontain"
  
  useEffect (() => {
    gsap.from(VideoAnimate, {
        y: 120,
        opacity: 0,
        duration: 1.2,
        ease: Power3.easeOut,
        scrollTrigger: {
            trigger: VideoAnimate,
            start: "-30% 100%",
            //markers: "true",
            toggleActions: "play none none pause"
        },
    });
    }, []);

    return (
      <div className=".videosection">
        <VideoSection
            initial="hidden"
            onClick={loadDetailHandler2}
            onMouseEnter= {()=>setType('view')}
            onMouseLeave={()=>setType('default')}
        >
            <VideoContainer
                onHoverStart={() => setHovered(!hovered)}
                onHoverEnd={() => setHovered(!hovered)}
                className="videocontain"
            >
                <Link to="/coding" style={{ textDecoration: 'none' }}>
                    <FeaturedVideo>
                      <FeaturedContent>
                        <Flex>
                            <h3>Development</h3>
                            <motion.div 
                              animate={{ opacity: hovered ? 1 : 0}}
                              transition={{ duration: 0.6, ease: [0.6, 0.05, -0.01, 0.9]}}
                              className="meta">
                                <h4>View More</h4>
                            </motion.div>
                        </Flex>
                        <h2 className="featured-title">
                            Coding <br/> The Designs
                        </h2>
                      </FeaturedContent>
                      <div className="featured_video_container">
                          <div className="overlay">
                            <video 
                                  loop
                                  autoPlay
                                  muted
                                  playsInline
                                  src={codeVideo} >
                              </video>
                          </div>
                        </div>  
                    </FeaturedVideo>
                </Link>
            </VideoContainer>
          </VideoSection>
      </div>  
      );
    });


const VideoSection = styled(motion.div)`
  margin-bottom: 200px;
  position: relative;
  @media (max-width: 768px) {
    margin-bottom: 8rem;
    height: 400px;
  }
  @media (max-width: 425px) {
    margin-bottom: 12rem;
  }
  a {
    //margin-bottom: 320px;
    position: relative;
    display: block;
    @media (max-width: 768px) {
    height: 400px;
    }
  }
  `

const FeaturedContent = styled(motion.div)`
  position: absolute;
  height: 480px;
  width: 100%;
  padding: 56px 124px;
  box-sizing: border-box;
  z-index: 3;
  top: 0;
  //display: flex;
  @media (max-width: 873px) {
    padding: 40px 32px;
    }
  @media (max-width: 768px) {
    padding: 16px 32px;
    height: 400px;
    }
  @media (max-width: 425px) {
    padding: 16px 32px;
    height: 400px;
  }
  h2 {
    @media (max-width: 425px) {
      //test
      -webkit-text-stroke: 0.5px;
      letter-spacing: 0.01rem;
    }
  }
  h3 {
    font-size: 1.4rem;
    font-weight: 400;
    z-index: 3;
    color: #dfdfdf;
  }
  .meta {
    display: flex;
    z-index: 3;
    color: #dfdfdf;
    h4 {
      font-weight: 400;
      &:last-child {
        margin-left: 1rem;
      }
    }
  }
  .featured-title {
    position: absolute;
    bottom: 12px;
    font-size: 56px;
    font-weight: 600;
    line-height: 58px;
    margin: 0;
    z-index: 3;
    color:white;
    line-height: 64px;
    mix-blend-mode: difference;
    color:#dfdfdf;
    @media (max-width: 580px) {
    font-size: 2rem;
    line-height: 2.2rem;
    bottom: 8%;
    }
    @media (max-width: 425px) {
      font-size: 2.6rem;
      line-height: 2.6rem;
      bottom: 10%;
    }
  }
`

const VideoContainer = styled(motion.div)`
  flex-grow: 1;
  margin: 0 auto;
  padding: 0 32px;
  position: relative;
  width: auto;
  height: 100%;
  @media (min-width: 1024px) {
    max-width: 960px;
  }
  @media (min-width: 1216px) {
    max-width: 1152px;
  }
  @media (min-width: 1408px) {
    max-width: 1244px;
  }
  @media (max-width: 768px) {
    width: 100%;
    padding: 0;
  }
`

const Flex = styled(motion.div)`
  //position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
`



const FeaturedVideo = styled(motion.div)`
  position: relative;
  z-index: 1;
  width: 100%;
  height: 540px;
  top: 0;
  display: block;
  overflow: hidden;
  object-fit: cover;
  @media (max-width: 768px) {
    height: auto;
  }
  .featured_video_container{
        display: block;
        width: 100%;
        overflow: hidden;
        height: 540px;
        display: flex;
        align-items: center;
        margin-bottom: 80px;
        top: 0;
        left: 0;
        @media (max-width: 768px) {
            height: 400px;
            margin-bottom: 0px;
            }
          @media (max-width: 425px) {
            height: 400px;
            margin-bottom: 0px;
            }
          video {
          object-fit: cover;
          position: relative;
          width: 100%;
          background: rgba(0,0,0,0.5);
          filter: brightness(0.65);
          top: 0;
          @media (max-width: 873px) {
            height: 550px;
            }
          @media (max-width: 768px) {
            height: 509px;
            width: auto;
            }
          @media (max-width: 500px) {
            height: 380px;
            width: auto;
            }
          }
    }
`

export default CodeSection